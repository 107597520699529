export const environment = {
  production: true,
  apiURL: 'https://devapi.cybfed.com/api/v1',
  filePath: 'https://velviooprod.s3.amazonaws.com/',
  socketUrl: 'https://devapi.cybfed.com',
  blogUrl: 'https://blog.cybfed.com',
  shortLinkUrl: 'https://blog.cybfed.com/rd/',
  authHeaderPrefix: 'cybfed-api <',
  portalUrl: 'https://dev.cybfed.com',
  timeStamp: '01/08/2022 09:02:59 AM CST',
  authHeaderSufix: '>',
  underConstruction: false,
  adsensePubId: 'ca-pub-2616911764893650',
  twitchHost: 'cybfed.com',
  ssrPort: 4200,
  cashForPeriod: 300000,
  google: {
    idAnalysis: '3984973334',
    idChannel: "G-8DZGYSKZQ0",
    tagManager: 'GTM-P62XSCN'
  },
  telegramChannel: "/Cybfed_dev_bot",
  firebase:{
    vapidKey: 'BNLuFDlG5gm81v-6gL9coPCiIWz51Y1YyLakhh4hGyx9lLOONxp7-uooOFKOVNv5onDo1Oly_hnPF1bIN0s0Hzw'
  },
  enableCookies: true,
  myBuyDetailUrl: 'https://mybuy.am/coupon/details/',
  stripeKeys: {
    PUBLIC_KEY: "pk_live_51H4WY8GLyQrM9VisCt5iK6DW2eWa23yu4bQMakEanC1qG9ycoNYBcFIkj9JBnQSXaQya0m7yYyPh9ErwFeq2s3dP00OKB9XrlK"
  },
};


