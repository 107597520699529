import {Component, Inject} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterModule} from '@angular/router';
import {CommonModule} from "@angular/common";
import {UpdateMetaTags} from "./core/utils/update-meta-tags";
import {filter} from "rxjs";
import {Meta} from "@angular/platform-browser";
import { DOCUMENT } from '@angular/common';
import {environment} from "../environments/environment";
import {StructuredDataService} from "./core/utils/structured-data.service";
import {TicketToMoneyPipe} from "./shared/pipes/ticket-to-money.pipe";
import {StorageService} from "./core/services/storage.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule
  ],
  providers: [
      UpdateMetaTags,
      StructuredDataService,
      TicketToMoneyPipe
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  constructor(
      private metadata: UpdateMetaTags,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      @Inject(DOCUMENT) private dom
  ) {
    this.metadata.updateMetaTags();
    this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateCanonicalUrl(environment.portalUrl + '/' +  this.router.url);
    });
    this.getReferralValue()
  }

  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }

  getReferralValue(){
    this.activatedRoute.queryParams.subscribe(params => {
      let referral  = params['referral'] || null;
      if (referral) {
        StorageService.setItem('referral', referral);
      }
    });
  }


}
