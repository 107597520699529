import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {environment} from "../../../environments/environment";
import {TicketToMoneyPipe} from "../../shared/pipes/ticket-to-money.pipe";

enum SeoStructureTypes{
    Product = 'Product',
    Organization = 'Organization',
    CreativeWork = 'CreativeWork',
    Brand = 'Brand',
    PostalAddress = 'PostalAddress',
    ContactPoint = 'ContactPoint',
    Person = 'Person',
    Event = 'Event',
    Offer = 'Offer',
    WebPage = 'WebPage',
    WebSite = 'WebSite',
    SportsTeam = 'SportsTeam',
    NewsArticle = 'NewsArticle'
}

@Injectable({
    providedIn: 'root'
})
export class StructuredDataService {
    private renderer: Renderer2;

    constructor(private rendererFactory: RendererFactory2,
                private ticket2Money: TicketToMoneyPipe) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    /**
     * Dynamically add structured data to the HTML head
     */
    injectStructuredData(data: object): void {
        const jsonLd = this.renderer.createElement('script');
        jsonLd.type = 'application/ld+json';
        jsonLd.text = JSON.stringify(data);
        this.renderer.appendChild(document.head, jsonLd);
    }

    /**
     * Remove all structured data scripts to prevent duplicates
     */
    removeStructuredData(): void {
        const scripts = document.querySelectorAll('script[type="application/ld+json"]');
        scripts.forEach(script => this.renderer.removeChild(document.head, script));
    }


    appendFeature(name: string, description: string, image: string, url: string, featuresList: string[]): void {
        const features =  {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Cybfed ESports Platform",
            "description": "A white-label esports platform for gamers, streamers, and teams, offering monetization and management tools.",
            "url": "https://cybfed.com/features",
            "featureList": [
                "Rewarded Tournaments",
                "Profile and Team Management",
                "Esports Statistics Tracking",
                "Sponsor Engagement"
            ],
            "offers": {
                "@type": "Offer",
                "url": "https://cybfed.com/signup",
                "price": "0",
                "priceCurrency": "USD",
                "availability": "https://schema.org/InStock"
            }
        }
        this.removeStructuredData();
        this.injectStructuredData(features);
    }

    appendTeam(teamName, teamDescription, url, logo, members, createdDate){
        const features =  {
            "@context": "https://schema.org",
            "@type": "SportsTeam",
            "name": teamName,
            "description": teamDescription,
            "url": url,
            "logo": logo,
            "sport": "Esports",
            "member": members.map((item)=>{
                return {
                    "@type": "Person",
                    "name": item.nickname,
                    "role": "Team Player",
                    "url": item.avatarUrl
                }
            }),
            "foundingDate": createdDate
        }
        this.removeStructuredData();
        this.injectStructuredData(features);
    }


    appendProduct(title, description, image, url, offers: [], createdBy){
        const aboutStruct = {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": title,
            "description": description,
            "url": url,
            "image": image,
            "brand": {
                "@type": "Brand",
                "name": createdBy
            },
            "offers": offers.map((item: any) =>{
                return {
                    "@type": "Offer",
                    "price": this.ticket2Money.transform( item.amount, 'USD'),
                    "priceCurrency": 'USD',
                    "url": url,
                    "availability": "https://schema.org/InStock"
                }
            })
        }

        this.removeStructuredData();
        this.injectStructuredData(aboutStruct);
    }


    appendEvent(eventTitle,
                eventDescription,
                startDate,
                url,
                organizer,
                organizerUrl,
                prizes,
                sponsors
                ){
        const aboutStruct = {
            "@context": "https://schema.org",
            "@type": "Event",
            "name": eventTitle,
            "description": eventDescription,
            "startDate": startDate,
            "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
            "eventStatus": "https://schema.org/EventScheduled",
            "location": {
                "@type": "VirtualLocation",
                "url": url
            },
            "offers":{
                "@type": "Offer",
                "url": url,
                "price": "0",
                "priceCurrency": "USD",
                "availability": "https://schema.org/InStock",
                "category": "Free Entry",
                "description": "Standard entry with no fees."
            },
            "sponsor": sponsors.map((item) => {
                return {
                    "@type": "Organization",
                    "name":item.name,
                    "url": item.link,
                    "logo": item.logoId.s3Url
                }
            }),
            "organizer": {
                "@type": "Organization",
                "name": organizer,
                "url": organizerUrl
            },
            "additionalProperty": {
                "@type": "PropertyValue",
                "name": "Rewards",
                "value": prizes.map((prize) => {
                    return prize.name + ' ' + prize.displayPrize;
                }).join(', ')
            }
        }

        this.removeStructuredData();
        this.injectStructuredData(aboutStruct);
    }


    appendCreative(name, description, url, image, creatorName, createdAt){
        const aboutStruct = {
            "@context": "https://schema.org",
            "@type": "CreativeWork",
            "creator": {
                "@type": "Organization",
                "name": creatorName
            },
            "name": name,
            "url": url,
            "logo": image,
            "datePublished": createdAt,
            "inLanguage": "en",
            "about": description
        }

        this.removeStructuredData();
        this.injectStructuredData(aboutStruct);
    }

    appendArticle(title, description, image, url, author, authorAvatar, createdDate, updatedDate, articleBody){
        const aboutStruct = {
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": url
            },
            "headline": title,
            "description": description,
            "image": image,
            "publisher": {
                "@type": "Organization",
                "name": author,
                "logo": {
                    "@type": "ImageObject",
                    "url": authorAvatar
                }
            },
            "datePublished": createdDate,
            "dateModified": updatedDate,
            "articleBody": articleBody,
            "articleSection": "Games and ESports"
        }

        this.removeStructuredData();
        this.injectStructuredData(aboutStruct);
    }


    appendPerson(playerName, playerDescription, playerSocials, playerLogo, playerUrl){
        const features =  {
            "@context": "https://schema.org",
            "@type": "Person",
            "name": playerName,
            "description": playerDescription,
            "url": playerUrl,
            "logo": playerLogo,
            "sameAs": playerSocials,
            "sport": "Esports"
        }
        this.removeStructuredData();
        this.injectStructuredData(features);
    }


    appendLanding(title, description, url, homeUrl, creatorName){
        const aboutStruct = {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": title,
            "url": url,
            "description": description,
            "publisher": {
                "@type": "Organization",
                "name": creatorName
            },
            "mainEntity": {
                "@type": "WebSite",
                "url": homeUrl
            }
        }

        this.removeStructuredData();
        this.injectStructuredData(aboutStruct);
    }


    appendBrand(name, description, logo, url, socialLinks){
        const aboutStruct = {
            "@context": "https://schema.org",
            "@type": "Brand",
            "name": name,
            "description": description,
            "url": url,
            "logo": logo,
            "sameAs": socialLinks,
            "brandStory": {
                "@type": "CreativeWork",
                "headline": name,
                "text": description
            }
        }

        this.removeStructuredData();
        this.injectStructuredData(aboutStruct);
    }

    appendAbout(name, description, url, image, socialPages, createdAt){
         const aboutStruct = {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": name,
            "url": url,
            "logo": image,
            "sameAs": socialPages,
            "description": description,
            "foundingDate": createdAt
         }

        this.removeStructuredData();
        this.injectStructuredData(aboutStruct);
    }


    contactUs(){
        const aboutStruct = {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Cybfed INC",
            "url": "https://cybfed.com",
            "logo": "https://cybfed.com/assets/image/cybfed-logo.png",
            "contactPoint": [
                {
                    "@type": "ContactPoint",
                    "contactType": "Customer Service",
                    "areaServed": "Global",
                    "availableLanguage": ["English", "Russian"],
                    "email": "support@cybfed.com"
                },
                {
                    "@type": "ContactPoint",
                    "contactType": "Sales",
                    "areaServed": "Global",
                    "availableLanguage": ["English", "Russian"],
                    "email": "mikayel@cybfed.com"
                }
            ],
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "123 Esports Avenue",
                "addressLocality": "Yerevan",
                "postalCode": "0010",
                "addressCountry": "Armenia"
            }
        }

        this.removeStructuredData();
        this.injectStructuredData(aboutStruct);
    }


}
