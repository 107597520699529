<header class="w-full relative bg-cover bg-no-repeat h-[300px]" style="background-image: url('/assets/image/uc-tournament-banner.jpg'); background-size: cover;">
  <nav class="mx-auto relative z-10 container flex items-center justify-between p-3 lg:px-6" aria-label="Global">
    <div class="md:flex mr-[36px] hidden">
      <a [routerLink]="['./']" class="-m-1.5 p-1.5">
        <span class="sr-only">CybFed ESports/CyberSport Arena</span>
        <img  class="w-[56px] h-auto" [ngSrc]="'/assets/image/cybfed-logo.png' | safe: 'url'" width="77" height="53" alt="CybFed logo" priority>
      </a>
    </div>
    <div class="flex max-w-7xl flex-end md:mr-6">
<!--      <div class="flex md:gap-x-5 md:justify-start ml-3 text-center">-->
<!--        <a [routerLink]="['./']" [routerLinkActive]="['text-primary']"  [routerLinkActiveOptions]="{ exact:true }" class="text-sm font-semibold text-white leading-6 text-center p-2">-->
<!--&lt;!&ndash;          <div class="sm:hidden block">&ndash;&gt;-->
<!--&lt;!&ndash;            <svg class="m-auto" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
<!--&lt;!&ndash;              <path d="M5 11L4.54243 10.4058C4.35804 10.5477 4.25 10.7673 4.25 11H5ZM14.0909 4L14.5485 3.40575C14.2788 3.19808 13.903 3.19808 13.6333 3.40575L14.0909 4ZM5.5917 23.4142L5.06405 23.9472L5.06405 23.9472L5.5917 23.4142ZM11.0606 24L11.0606 23.25H11.0606V24ZM11.3635 24L11.3635 24.75C11.5624 24.75 11.7531 24.671 11.8938 24.5303C12.0344 24.3897 12.1135 24.1989 12.1135 24H11.3635ZM11.3635 14V13.25C10.9493 13.25 10.6135 13.5858 10.6135 14H11.3635ZM16.818 14H17.568C17.568 13.5858 17.2322 13.25 16.818 13.25V14ZM16.818 24H16.068C16.068 24.1989 16.147 24.3897 16.2877 24.5303C16.4283 24.671 16.6191 24.75 16.818 24.75L16.818 24ZM17.1212 24V23.25H17.1212L17.1212 24ZM22.5901 23.4142L22.0625 22.8812V22.8812L22.5901 23.4142ZM23.1818 11H23.9318C23.9318 10.7673 23.8238 10.5477 23.6394 10.4058L23.1818 11ZM5.45757 11.5942L14.5485 4.59425L13.6333 3.40575L4.54243 10.4058L5.45757 11.5942ZM5.75 22V11H4.25V22H5.75ZM6.11936 22.8812C5.88197 22.6462 5.75 22.3291 5.75 22H4.25C4.25 22.7318 4.54371 23.4321 5.06405 23.9472L6.11936 22.8812ZM7.0202 23.25C6.68091 23.25 6.35701 23.1165 6.11936 22.8812L5.06405 23.9472C5.58412 24.4621 6.28792 24.75 7.0202 24.75V23.25ZM11.0606 23.25H7.0202V24.75H11.0606V23.25ZM11.3635 23.25L11.0606 23.25L11.0606 24.75L11.3635 24.75L11.3635 23.25ZM12.1135 24V14H10.6135V24H12.1135ZM11.3635 14.75H16.818V13.25H11.3635V14.75ZM16.068 14V24H17.568V14H16.068ZM17.1212 23.25L16.818 23.25L16.818 24.75L17.1212 24.75L17.1212 23.25ZM21.1616 23.25H17.1212V24.75H21.1616V23.25ZM22.0625 22.8812C21.8248 23.1165 21.5009 23.25 21.1616 23.25V24.75C21.8939 24.75 22.5977 24.4621 23.1178 23.9472L22.0625 22.8812ZM22.4318 22C22.4318 22.3291 22.2998 22.6462 22.0625 22.8812L23.1178 23.9472C23.6381 23.4321 23.9318 22.7318 23.9318 22H22.4318ZM22.4318 11V22H23.9318V11H22.4318ZM13.6333 4.59425L22.7242 11.5942L23.6394 10.4058L14.5485 3.40575L13.6333 4.59425Z" fill="rgb(179, 0, 47)"/>&ndash;&gt;-->
<!--&lt;!&ndash;            </svg>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--          <div class="block">-->
<!--            {{'home' | translate}}-->
<!--          </div>-->
<!--        </a>-->
<!--        <a [routerLink]="['tournaments']" [routerLinkActive]="['text-primary']" class="text-sm font-semibold leading-6 text-white p-2">-->
<!--&lt;!&ndash;          <div class="sm:hidden block">&ndash;&gt;-->
<!--&lt;!&ndash;            <svg class="m-auto"  width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
<!--&lt;!&ndash;              <path d="M20.0667 6.5V7.1H20.6667H25C25.2599 7.1 25.4 7.28523 25.4 7.4375V9.625C25.4 10.7441 24.6324 12.0167 23.0851 13.0613L23.0842 13.0619C21.8641 13.8888 20.3637 14.4208 18.7737 14.5909L18.4881 14.6215L18.3328 14.863C17.7158 15.8228 17.0483 16.4982 16.5393 16.9312C16.285 17.1476 16.071 17.3028 15.9238 17.4021C15.8502 17.4518 15.7934 17.4874 15.7569 17.5096C15.7386 17.5207 15.7253 17.5284 15.7176 17.5329L15.7106 17.5369L15.7101 17.5372L15.7101 17.5372L15.7095 17.5375L15.4 17.7075V18.0625V20.875V21.475H16H18C18.6321 21.475 19.1609 21.6494 19.5171 21.9258C19.8597 22.1915 20.0667 22.5663 20.0667 23.0625V23.4H7.93333V23.0625C7.93333 22.5663 8.1403 22.1915 8.48285 21.9258C8.83909 21.6494 9.36792 21.475 10 21.475H12H12.6V20.875V18.0625V17.7075L12.2905 17.5375L12.2899 17.5372L12.2899 17.5372L12.2894 17.5369L12.2824 17.5329C12.2747 17.5284 12.2614 17.5207 12.2431 17.5096C12.2066 17.4874 12.1498 17.4518 12.0762 17.4021C11.929 17.3028 11.715 17.1476 11.4607 16.9312C10.9517 16.4982 10.2842 15.8228 9.6672 14.863L9.51195 14.6215L9.2265 14.5909C7.64077 14.4208 6.13989 13.8889 4.91505 13.0614L4.9143 13.0609C3.3641 12.017 2.6 10.7446 2.6 9.625V7.4375C2.6 7.28523 2.74014 7.1 3 7.1H7.33333H7.93333V6.5V4.9375C7.93333 4.78523 8.07347 4.6 8.33333 4.6H19.6667C19.9265 4.6 20.0667 4.78523 20.0667 4.9375V6.5ZM5.8014 12.0283L5.80182 12.0286C6.37273 12.4139 7.01181 12.719 7.69066 12.9382L8.77641 13.2887L8.44991 12.1955C8.17285 11.2679 7.98131 10.2018 7.94135 8.98038L7.92236 8.4H7.34167H4.66667H4.06667V9V9.625C4.06667 10.0286 4.2568 10.4562 4.53402 10.841C4.82177 11.2404 5.24357 11.6511 5.8014 12.0283ZM22.1982 12.0286L22.1987 12.0282C22.604 11.7541 23.0173 11.3973 23.3365 11.0088C23.6393 10.6403 23.9333 10.1514 23.9333 9.625V9V8.4H23.3333H20.6542H20.0735L20.0545 8.98038C20.0145 10.2018 19.823 11.2679 19.5459 12.1955L19.2194 13.2887L20.3052 12.9382C20.9829 12.7194 21.6264 12.4145 22.1982 12.0286Z" stroke="white" stroke-width="1.2"/>&ndash;&gt;-->
<!--&lt;!&ndash;            </svg>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--          <div class="block">-->
<!--            {{'portfolio' | translate}}-->
<!--          </div>-->
<!--        </a>-->
<!--        <a [routerLink]="['challenges']" [routerLinkActive]="['text-primary']" class="text-sm  font-semibold leading-6 text-white p-2">-->
<!--&lt;!&ndash;          <div class="sm:hidden block text-xl">&ndash;&gt;-->
<!--&lt;!&ndash;            <i class="fa fa-hacker-news-alt"></i>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--          <div class="block">-->
<!--            {{'news' | translate}}-->
<!--          </div>-->
<!--        </a>-->
<!--        <a [routerLink]="['marketplace']" [routerLinkActive]="['text-primary']" class="text-sm  font-semibold leading-6 text-white p-2">-->
<!--&lt;!&ndash;          <div class="sm:hidden block">&ndash;&gt;-->
<!--&lt;!&ndash;            <svg class="m-auto"  width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
<!--&lt;!&ndash;              <path d="M22.0994 14.5V24H6.89941V14.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>&ndash;&gt;-->
<!--&lt;!&ndash;              <path d="M24 9.75H5V14.5H24V9.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>&ndash;&gt;-->
<!--&lt;!&ndash;              <path d="M14.5 24V9.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>&ndash;&gt;-->
<!--&lt;!&ndash;              <path d="M14.5006 9.75H10.2256C9.5957 9.75 8.99161 9.49978 8.54621 9.05438C8.10081 8.60898 7.85059 8.00489 7.85059 7.375C7.85059 6.74511 8.10081 6.14102 8.54621 5.69562C8.99161 5.25022 9.5957 5 10.2256 5C13.5506 5 14.5006 9.75 14.5006 9.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>&ndash;&gt;-->
<!--&lt;!&ndash;              <path d="M14.5 9.75H18.775C19.4049 9.75 20.009 9.49978 20.4544 9.05438C20.8998 8.60898 21.15 8.00489 21.15 7.375C21.15 6.74511 20.8998 6.14102 20.4544 5.69562C20.009 5.25022 19.4049 5 18.775 5C15.45 5 14.5 9.75 14.5 9.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>&ndash;&gt;-->
<!--&lt;!&ndash;            </svg>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--          <div class="block">-->
<!--            {{'store' | translate}}-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
    </div>
  </nav>
  <div class="header-mask absolute top-0 left-0 w-full h-full"></div>
  <div class="flex justify-end mx-2 my-4 z-10 relative text-right gap-x-8">
<!--    <div>-->
<!--      <img loading="lazy"  src="assets/image/cybfed-logo.png" class="h-14" />-->
<!--    </div>-->
<!--    <div>-->
<!--      <img loading="lazy"  src="assets/flags/en.ico" class="h-14" />-->
<!--    </div>-->
  </div>
</header>
