import {Pipe, PipeTransform} from '@angular/core';
import {appConstants} from "../../constants";

@Pipe({
  standalone: true,
  name: 'ticket2Money',
})
export class TicketToMoneyPipe implements PipeTransform {

  transform(value: any, currencyCode:string, ...args: any[]): any {

    return value * 10 + ' Դ';
  }

}
